.digital-readout-adjustable {
  display: flex;
  flex-direction: row;
}
.button-container {
  display: flex;
  flex-direction: column;
}
.button-container > button {
  flex-grow: 1;
  font-family: "digital", monospace;
  background-color: black;
  color: greenyellow;
  font-size: 1em;
}
.button-container > button:hover {
  background-color: darkgrey;
}

@font-face {
  font-family: 'digital';
  src: url(./fonts/digital-7\ \(mono\).ttf);
}

.digital-readout {
  background-color: black;
  color: #39ff14;
  padding: 10px;
  font-size: 25px;
}

input {
  font-family: 'digital';
  font-size: 30px !important;
}